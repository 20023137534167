<template>
  <div class="mContact">
    <p class="title">河小象课程报名协议</p>
    <p class="aboutDes" style="margin-bottom:0;">本协议中,“甲方”是指“河小象网络科技有限公司”,“乙方”是指购买甲方课程的自然人。</p>
    <p class="aboutDes" style="margin-top:0.2rem;">乙方一经支付河小象在线课程费用即视为乙方已理解并接受本协议。</p>
    <p class="childTitle">第一部分 课程信息和服务</p>
    <div class="aboutDes">
      <p>1、甲方将根据学员情况为其评估书写水平，由专业老师推荐选择，经学员及其家长同意后，确定购买，一经确定不得以任何理由更换课程。</p>
      <p>2、课程在乙方同意激活后，除国定小长假以外（甲方具体通知），课程每天都按时进行，课程一旦开通就不接受中途请假。</p>
      <p>3、乙方在甲方提供体验服务并由专职人员介绍后购买甲方指定课程。</p>
      <p>4、乙方在支付相应费用后90天内激活账号，超过约定时间未激活，甲方默认激活。</p>
      <p>5、乙方可在甲方为其激活课程后使用账号密码登录线上平台进行系统学习。</p>
      <p>6、甲方为学员提供写字在线教学服务,学习时间、地点、时长均不限。学员完成练习后把练习作业拍成照片按指定方式提交作业。</p>
      <p>7、甲方为学员提供一对一在线点评服务，每天一节课，安排学员解锁相应课程内容。</p>
      <p>8、课程有效期是按照购买约定时长（例如：半年课程、一年课程，从课程激活之日计算时间），乙方学员必须在有效期内完成相应课程的学习。</p>
      <p>9、甲方在乙方的使用账号页面设置有效期提示，学员未能在有效期内登录平台进行提交作业的,甲方不再为乙方课程延时。</p>
    </div>
    <p class="childTitle">第二部分 费用细则</p>
    <div class="aboutDes">
      <p>1、乙方按照甲方规定要求缴纳学费。</p>
      <p>2、乙方在课程激活15个工作日后，可申请开具相应票据。</p>
      <p>3、关于退费</p>
      <p>(1)乙方根据本协议向甲方支付课程费用后，如有对甲方服务不满或其他原因等情况下，可在购买之日起15天内，向甲方提出申请解除协议，甲方无条件退款。</p>
      <p>(2)乙方根据本协议向甲方支付课程费用后，超过购买之日起15天后，向甲方提出申请解除协议，甲方客服将在7个工作日内处理退款申请，自退款申请生成后，甲方将按照付款时的方式和账号进行退款。甲方将按照下方退款政策为乙方退款：</p>
      <p>①百宝箱属于定制品+消耗品，概不退还，不用寄回，折价200元。</p>
      <p>②从激活时间开始算，16元/日进行课程款折价，退费金额=乙方已支付的课程费用-乙方已完成的课时费用。</p>
      <p>③甲方向乙方赠送的全部课程、优惠等项目对应的金额为0元,在乙方提出退款申请之后将自动作废。</p>
    </div>
    <p class="childTitle">第三部分 双方的权利义务</p>
    <div class="aboutDes">
      <p>一、甲方的权利和义务</p>
      <p>1、在保证培训质量的前提下,甲方有权改进、调整变更其培训服务、课程安排或老师安排等其它事项，并将上述任何变更通过网站或其他方式及时通知乙方。</p>
      <p>2、甲方应根据乙方提出的要求,在约定范围内提供相应技术支持服务。</p>
      <p>二、在约定范围内提供相应技术支持服务。</p>
      <p>1、乙方监护人需在课程开通之日按甲方要求监督学员按要求完成课程学习，保质保量完成每日作业。</p>
      <p>2、乙方应妥善保管其课程账户和密码，不得出售、转借或用于其它商业目的。</p>
      <p>3、乙方及学员在课程学习期内,如遇有任何问题,可以随时与甲方的工作人员取得联系，乙方有权对甲方的课程服务提出意见。</p>
      <p>4、乙方及学员不得影响甲方网站的正常运营，不得影响课程进程及其他学员的学习活动；不得发布不实言论损害甲方或甲方员工的名誉。</p>
    </div>
    <p class="childTitle">第四部分 保密细则</p>
    <div class="aboutDes">
      <p>1、甲方为培训课程所制作的全部资料，在教学过程中产生的全部资料，甲方网站上所显示、使用或提供的软件、程序及内容，未经甲方同意，乙方及学员在任何时候均不得以商业目的对上述资料进行利用；以任何方式翻译、复制、传播、编辑上述资料；以及将上述资料透露给任何第三方。</p>
      <p>2、乙方同意在乙方学员参与的培训课程中,或乙方及学员参与甲方组织、举办的所有活动中,甲方可以进行拍照、摄像、录音、录像等记录活动。甲方对上述记录活动过程中所产生的作品、录音录像制品等单独地享有全部、完整的知识产权。</p>
      <p>3、甲方同意依法为乙方及学员的个人信息采取严格的保密措施。未经乙方同意,甲方不得将上述包含乙方及学员肖像或者显示个人身份的其他个人信息进行公开地商业使用。仅当以下情况之一发生时,甲方可以对乙方的肖像、姓名等个人信息进行合理使用,甲方无须再单独取得乙方同意,也无须向乙方支付任何费用，乙方及学员的肖像、姓名等合法民事权益,已被明确放弃。为甲方内部的展示使用需要,包括单不限于在内部会议中展示、将相关资料作为历史档案存留于甲方档案库或者服务器上等。</p>
    </div>
    <p class="childTitle">第五部分 法律管辖和争议解决</p>
    <div class="aboutDes">
      <p>1、本协议的定理、执行和解释及争议的解决均应适用于中华人民共和国法律。</p>
      <p>2、协议中未尽事宜，如有任何争议，双方应当尽量友好协商解决。协商不成，双方同意将争议提交至甲方所在地仲裁机构裁决。</p>
    </div>
    <m-footer class="footer"></m-footer>
  </div>
</template>

<script>
import mFooter from '../components/mFooter';
export default {
  components:{
    mFooter
  },
  name: 'mContact',
  data () {
    return {
    }
  },
  created() {
    this.setPageSize();
  },
  methods: {
    setPageSize() {
      !function(e,t){function n(){t.body?t.body.style.fontSize=12*o+"px":t.addEventListener("DOMContentLoaded",n)}function d(){var e=i.clientWidth/10;i.style.fontSize=e+"px"}var i=t.documentElement,o=e.devicePixelRatio||1;if(n(),d(),e.addEventListener("resize",d),e.addEventListener("pageshow",function(e){e.persisted&&d()}),o>=2){var a=t.createElement("body"),s=t.createElement("div");s.style.border=".5px solid transparent",a.appendChild(s),i.appendChild(a),1===s.offsetHeight&&i.classList.add("hairlines"),i.removeChild(a)}}(window,document);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mContact {
  margin-top: 1.52rem;
  padding: 0.89rem 0 0 ;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: auto;
  color: #fff;
}
.title {
  margin-bottom: 0.77rem;
  height: 0.75rem;
  line-height: 0.75rem;
  font-size-adjust: 0.75rem;
  color: #515558;
}
.aboutDes {
  margin-bottom: 0.79rem;
  padding: 0 0.93rem;
  width: 100vw;
  box-sizing: border-box;
  height: auto;
  line-height: 0.64rem;
  font-size: 0.37rem;
  color: #515558;
  text-align: left;
}
.aboutDes p {
  margin-bottom: 0.2rem;
  text-indent: 0.37rem;
}
.childTitle {
  position: relative;
  margin-bottom: 0.5rem;
  padding: 0 0.93rem;
  width: 100vw;
  height: 0.48rem;
  box-sizing: border-box;
  line-height: 0.48rem;
  font-size: 0.48rem;
  font-weight: bold;
  color: #515558;
  text-align: left;
}
.childTitle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0.54rem;
  width: 0.1rem;
  height: 0.48rem;
  background-color: #13B4FE;
}
.footDes {
  margin-bottom: 0.79rem;
  padding: 0 0.43rem;
  width: 100vw;
  box-sizing: border-box;
  height: auto;
  line-height: 0.67rem;
  font-size: 0.37rem;
  color: #13B4FE;
  text-align: center;
}
</style>

<template>
  <div class="mFooter">
    <p class="p1">客服热线</p>
    <p class="p2"><a href="tel:400-163-3686">400-163-3686</a></p>
    <p class="p1">服务时间：周一到周六  早9：30- 18：30</p>
    <div class="routerList">
      <span @click.stop="routerTo('contact')">联系我们</span>
      <span @click.stop="routerTo('about')">关于我们</span>
      <span @click.stop="routerTo('aggreement')">用户协议</span>
    </div>
    <p class="reference">杭州河象网络科技有限公司 (ICP 浙B2-20181300)</p>
  </div>
</template>

<script>

export default {
  name: 'mFooter',
  data () {
    return {
    }
  },
  created() {
    this.setPageSize();
  },
  methods: {
    setPageSize() {
      !function(e,t){function n(){t.body?t.body.style.fontSize=12*o+"px":t.addEventListener("DOMContentLoaded",n)}function d(){var e=i.clientWidth/10;i.style.fontSize=e+"px"}var i=t.documentElement,o=e.devicePixelRatio||1;if(n(),d(),e.addEventListener("resize",d),e.addEventListener("pageshow",function(e){e.persisted&&d()}),o>=2){var a=t.createElement("body"),s=t.createElement("div");s.style.border=".5px solid transparent",a.appendChild(s),i.appendChild(a),1===s.offsetHeight&&i.classList.add("hairlines"),i.removeChild(a)}}(window,document);
    },
    //路由跳转
    routerTo(path) {
      console.log(path)
      let newPath = '/m/' + path;
      this.$router.push({path: newPath});
      console.log(newPath)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mFooter {
  padding: 0.69rem 1.3rem 0.69rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 4.71rem;
  color: #fff;
  background-color: #333;
}
.p1 {
  height: 0.37rem;
  line-height: 0.37rem;
  font-size: 0.37rem;
}
.p2 {
  height: 1rem;
  line-height: 1rem;
  font-size: 1rem;
}
.p2 a {
  color: #fff;
  text-decoration: none;
}
.routerList {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 6.91rem;
  height: 0.37rem;
  line-height: 0.37rem;
  font-size: 0.37rem;
}
.reference {
  font-size: 0.16rem;
  color: #bbb;
}
</style>
